<template>
  <section class="notFound h-100 w-100 d-flex mt-5 mb-5">
    <v-card color="primary" class="text-white m-auto p-5">
      <h1 tag="h1">404</h1>
      <h3 tag="h3">Page not Found</h3>
      <div class="mt-5">
        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
        <p class="mt-5 mb-0">Redirecting to Landing Page</p>
        <countdown tag="h3" :time="4000">
          <template slot-scope="props">{{ displayTime(props) }}</template>
        </countdown>
      </div>
    </v-card>
  </section>
</template>

<script>
export default {
  data() {
    return {
      timer: null,
    }
  },
  beforeDestroy() {
    if(this.timer) {
      clearTimeout(this.timer);
    }
  },
  mounted() {
    this.timer = setTimeout(() => {
      this.$router.push({ name: 'landing' }).catch();
    }, 4000);
  },
  methods: {
    fTime(number) {
      return ('0' + number).slice(-2);
    },
    displayTime(props) {
      return `${this.fTime(props.hours)}:${this.fTime(props.minutes)}:${this.fTime(props.seconds)}`;
    }
  }
};
</script>

<style lang="scss">
.notFound {
  h1 {
    font-size: 10em;
  }
  h3 {
    font-size: 3em;
  }
}
@media (max-width: 768px) {
  .notFound {
    h1 {
      font-size: 6em;
    }
    h3 {
      font-size: 1em;
    }
  }
}
</style>
